import React from 'react';
import { Oval } from 'react-loader-spinner';

const CircularProgress = () => {
   return (
      <>
         <Oval
            height={25}
            width={25}
            color="#5e5e5e"
            secondaryColor="#d9d9d9"
            strokeWidth={2}
            strokeWidthSecondary={2}

         />
      </>
   );
};

export default CircularProgress;