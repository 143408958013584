import React from 'react';
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';

const CustomTextfield = ({ label, type, name, register, errors }) => {
   const theme = useTheme();
   const smWidth = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <Box>
         <Typography
            sx={theme => ({
               color: 'text.primary',
               fontWeight: 600,
               fontSize: '15px',
               marginBottom: '5px',
               [theme.breakpoints.down('sm')]: {
                  fontSize: '14px'
               }
            })}
         >
            {label}
         </Typography>
         <TextField
            required
            variant='outlined'
            name={name}
            type={type}
            {...register(name, { required: true })}
            error={errors[name] ? true : false}
            sx={{
               width: '100%',
               backgroundColor: '#F8F9F9',
               borderRadius: '5px',
               '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                     borderColor: 'rgba(0, 0, 0, 0.18)',
                     borderRadius: '5px'
                  },
                  '&:hover fieldset': {
                     borderColor: 'rgba(0, 0, 0, 0.18)'
                  },
                  '&.Mui-focused fieldset': {
                     borderColor: 'text.main'
                  }
               }
            }}
            inputProps={{
               style: {
                  height: smWidth ? '9px' : '12px',
                  margin: '0 3px',
                  fontSize: '15px',
                  fontWeight: 500,
                  padding: type === 'file' && (smWidth ? '10px 0 24px 10px' : '12px 0 22px 10px')
               }
            }}
         />
         <Typography
            sx={{
               textAlign: 'start',
               fontSize: '13px',
               marginTop: '4px',
               fontWeight: 600,
               color: '#ff3333'
            }}
         >
            {errors[name]?.message}
         </Typography>
      </Box>
   );
};

export default CustomTextfield;