import React from 'react';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import Spinner from '../UI/Spinner';
import ErrorCard from '../UI/ErrorCard';
import axios from 'axios';
import { useQuery } from 'react-query';
import BackToTop from '../UI/BackToTop';
import PaymentFormModal from '../Forms/PaymentFormModal';
import EnquiryNowFormModal from '../Forms/EnquiryNowFormModal';
import WhatsappButton from '../UI/WhatsappButton';
import ScrollToTop from '../UI/ScrollToTop';
import CustomSnackbar from '../UI/CustomSnackbar';

const navFetcher = () => {
   return axios.get('https://infogps.in/gpsbackend/wp-json/acf/v2/options');
};

const Wrapper = ({ children }) => {
   const { isLoading, data, isError } = useQuery('navbar-data', navFetcher);

   if (isLoading) {
      return <Spinner />;
   };

   if (isError) {
      return <ErrorCard />;
   };

   return (
      <>
         <Navigation data={data.data.acf} />
         {children}
         <BackToTop />
         <PaymentFormModal />
         <EnquiryNowFormModal />
         <WhatsappButton data={data.data.acf} />
         <ScrollToTop />
         <CustomSnackbar />
         <Footer data={data.data.acf} />
      </>
   );
};

export default Wrapper;