import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton, ListItemButton, Typography } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { uiContext } from '../../context/ContextProvider';
import logo from '../../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';

const navItems = [
   {
      title: 'Home'
   },
   {
      title: 'Services'
   },
   {
      title: 'Apply For Job'
   },
   {
      title: 'Gallery'
   },
   {
      title: 'Contact'
   }
];

const Sidebar = () => {
   const { menu, setMenu } = useContext(uiContext);

   const { pathname } = useLocation();

   const toggleDrawer = () => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setMenu(false);
   };

   return (
      <div>
         <Drawer
            anchor={'right'}
            open={menu}
            onClose={toggleDrawer('right', false)}
            sx={{ zIndex: 99999 }}
         >
            <Box
               sx={{
                  width: 250,
                  height: '100%',
                  zIndex: 999999,
                  backgroundColor: '#000'
               }}
               role="presentation"
               onClick={toggleDrawer}
               onKeyDown={toggleDrawer}
            >
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'flex-start',
                     justifyContent: 'space-between',
                     marginBottom: '2.5rem',
                     padding: '24px 20px 0 20px',
                  }}
               >
                  <Link
                     to='/'
                     onClick={() => setMenu(false)}
                  >
                     <img
                        src={logo}
                        alt=""
                        style={{ height: '50px' }}
                     />
                  </Link>
                  <IconButton onClick={() => setMenu(false)}>
                     <FaTimes style={{ fontSize: '20px', color: '#ab8c44' }} />
                  </IconButton>
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                  }}
               >
                  {
                     navItems.map((item, index) => (
                        <Link
                           to={item.title === 'Home' ? '/' : `/${item.title.split(' ').join('').toLowerCase()}`}
                           key={index}
                        >
                           <ListItemButton
                              onClick={() => setMenu(false)}
                              disableRipple
                              key={index}
                              sx={{
                                 padding: '20px 24px',
                                 borderBottom: '1px dotted #ab8c44',
                                 borderTop: index === 0 && '1px dotted #ab8c44',
                                 backgroundColor: (pathname === '/' ? '/home' : pathname) === `/${item.title.split(' ').join('').toLowerCase()}` ? '#FFE19B25' : '',
                                 color: (pathname === '/' ? '/home' : pathname) === `/${item.title.split(' ').join('').toLowerCase()}` ? 'primary.main' : '#ab8c44',
                                 '&:hover': {
                                    backgroundColor: (pathname === '/' ? '/home' : pathname) === `/${item.title.split(' ').join('').toLowerCase()}` ? '#FFE19B25' : '#FFE19B25',
                                    color: (pathname === '/' ? '/home' : pathname) === `/${item.title.split(' ').join('').toLowerCase()}` ? '' : '#ab8c44',
                                 }
                              }}
                           >
                              <Typography
                                 sx={theme => ({
                                    fontSize: '14px',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    transition: '300ms ease',
                                    [theme.breakpoints.down('md')]: {
                                       fontSize: '13px'
                                    }
                                 })}
                              >
                                 {item.title}
                              </Typography>
                           </ListItemButton>
                        </Link>
                     ))
                  }
               </Box>
            </Box>
         </Drawer>
      </div >
   );
};

export default Sidebar;