import React from 'react';
import { Button } from '@mui/material';
import CircularProgress from '../UI/CircularProgress';

const PrimaryButton = ({ loading, text, bgColor, textColor, type, width }) => {
   return (
      <Button
         variant='contained'
         disableElevation
         type={type ? type : 'text'}
         disabled={loading}
         sx={theme => ({
            minHeight: 0,
            minWidth: 0,
            height: '45px',
            width: width,
            padding: !width && '10px 20px',
            backgroundColor: bgColor,
            textTransform: 'none',
            color: textColor,
            fontWeight: textColor === 'text.primary' ? 600 : 500,
            '&:hover': {
               backgroundColor: bgColor
            },
            [theme.breakpoints.down('md')]: {
               height: '42px',
               padding: !width && '8px 16px'
            }
         })}
      >
         {loading ? <CircularProgress /> : text}
      </Button>
   );
};

export default PrimaryButton;