import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Box, IconButton, ListItemButton, Typography } from '@mui/material';
import { BsFillEnvelopeFill, BsFillTelephoneFill, BsInstagram } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';
import { RiMenu3Fill } from 'react-icons/ri';
import { BsYoutube } from 'react-icons/bs';
import { ImGoogle3 } from 'react-icons/im';
import PrimaryButton from '../UI/PrimaryButton';
import { uiContext } from '../../context/ContextProvider';
import { Link, useLocation } from 'react-router-dom';

const navlinks = ['Home', 'Services', 'Apply For Job', 'Gallery', 'Contact'];

const Navbar = ({ data, mdWidth, showDrawer }) => {
   const { menu, setMenu, setEnquiryForm } = useContext(uiContext);
   const [scrolled, setScrolled] = useState(false);

   const { pathname } = useLocation();

   const handleScroll = useCallback(() => {
      if (window.scrollY > 50) {
         setScrolled(true);
      } else {
         setScrolled(false);
      };
   }, []);

   useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
   }, [handleScroll]);

   const headerInfo = [
      {
         icon: <BsFillEnvelopeFill style={{ fontSize: '1.25rem', marginRight: '8px' }} />,
         text: data.email,
         link: `mailto:${data.email}`
      },
      {
         icon: <BsFillTelephoneFill style={{ fontSize: '1.25rem', marginRight: '8px' }} />,
         text: data.mobile,
         link: `tel:${data.mobile}`
      }
   ];

   const socialIcons = [
      {
         icon: <FaFacebook style={{ fontSize: '1.3rem' }} />,
         link: data.facebook
      },
      {
         icon: <BsInstagram style={{ fontSize: '1.25rem' }} />,
         link: data.instagram
      },
      {
         icon: <ImGoogle3 style={{ fontSize: '1.25rem' }} />,
         link: data.google_plus
      },
      {
         icon: <BsYoutube style={{ fontSize: '1.3rem' }} />,
         link: data.youtube
      }
   ];

   return (
      <>
         {/* Header Info */}
         <Box
            sx={theme => ({
               backgroundColor: 'secondary.main',
               color: '#fff',
               position: 'fixed',
               top: 0,
               left: 0,
               right: 0,
               zIndex: 896,
               [theme.breakpoints.down(768)]: {
                  display: 'none'
               }
            })}
         >
            <Box
               className='container'
               sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
               }}
            >
               <Box
                  sx={theme => ({
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     paddingTop: '8px',
                     paddingBottom: '8px',
                     width: '75%',
                     [theme.breakpoints.down('md')]: {
                        justifyContent: 'flex-end'
                     }
                  })}
               >
                  {
                     headerInfo.map((item, index) => (
                        <a
                           key={index}
                           href={item.link}
                           target="_blank"
                           rel="noreferrer"
                        >
                           <Typography
                              sx={theme => ({
                                 fontSize: '14px',
                                 color: 'primary.main',
                                 display: 'flex',
                                 alignItems: 'center',
                                 [theme.breakpoints.down('md')]: {
                                    display: 'none'
                                 }
                              })}
                           >
                              {item.icon} {item.text}
                           </Typography>
                        </a>
                     ))
                  }
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '12px',
                        color: 'primary.main'
                     }}
                  >
                     {
                        socialIcons.map((item, index) => (
                           <Fragment key={index}>
                              <a
                                 href={item.link}
                                 target='_blank'
                                 rel="noreferrer"
                                 style={{
                                    color: '#FFE19B',
                                    display: 'flex',
                                    alignItems: 'center',
                                 }}
                              >
                                 {item.icon}
                              </a>
                           </Fragment>
                        ))
                     }
                  </Box>
               </Box>
            </Box>
         </Box>

         {/* Extra Layer */}
         <Box
            sx={theme => ({
               position: 'fixed',
               top: '5px',
               left: 0,
               borderBottom: '50px solid #FFE19B',
               borderRight: '25px solid transparent',
               height: 0,
               width: '30%',
               zIndex: 897,
               [theme.breakpoints.down('xl')]: {
                  width: '20%'
               },
               [theme.breakpoints.down('lg')]: {
                  width: '200px'
               },
               [theme.breakpoints.down(768)]: {
                  display: 'none'
               }
            })}
         />

         {/* Navbar */}
         <Box
            sx={theme => ({
               backgroundColor: 'primary.main',
               position: 'fixed',
               top: '36px',
               left: 0,
               right: 0,
               zIndex: 898,
               padding: '0.5rem 0',
               boxShadow: scrolled ? '0 2px 5px -2px rgba(0, 0, 0, 0.2)' : '',
               [theme.breakpoints.down(768)]: {
                  top: 0
               }
            })}
         >
            <Box
               className='container'
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
               }}
            >
               <Link to='/'>
                  <img
                     src={data.logo}
                     alt=""
                     style={{
                        height: mdWidth ? '70px' : '90px',
                        marginTop: showDrawer ? 0 : '-32px'
                     }}
                  />
               </Link>
               <Box
                  sx={theme => ({
                     display: 'flex',
                     alignItems: 'center',
                     columnGap: '4rem',
                     [theme.breakpoints.down('lg')]: {
                        columnGap: '2rem'
                     },
                     [theme.breakpoints.down(1050)]: {
                        columnGap: '1.5rem'
                     },
                     [theme.breakpoints.down('md')]: {
                        columnGap: '0.5rem'
                     },
                     [theme.breakpoints.down('850')]: {
                        display: 'none'
                     }
                  })}
               >
                  {
                     navlinks.map((text, index) => (
                        <Link to={text === 'Home' ? '/' : `/${text.split(' ').join('').toLowerCase()}`} key={index}>
                           <ListItemButton
                              disableRipple
                              sx={{
                                 padding: '6px 12px',
                                 // borderRadius: '5px',
                                 backgroundColor: (pathname === '/' ? '/home' : pathname) === `/${text.split(' ').join('').toLowerCase()}` ? '#111' : '',
                                 color: (pathname === '/' ? '/home' : pathname) === `/${text.split(' ').join('').toLowerCase()}` ? 'primary.main' : 'text.primary',
                                 '&:hover': {
                                    backgroundColor: (pathname === '/' ? '/home' : pathname) === `/${text.split(' ').join('').toLowerCase()}` ? '#111' : '',
                                    color: (pathname === '/' ? '/home' : pathname) === `/${text.split(' ').join('').toLowerCase()}` ? 'primary.main' : 'text.primary',
                                 }
                              }}
                           >
                              <Typography
                                 sx={theme => ({
                                    fontWeight: 600,
                                    [theme.breakpoints.down('md')]: {
                                       fontSize: '15px'
                                    }
                                 })}
                              >
                                 {text}
                              </Typography>
                           </ListItemButton>
                        </Link>
                     ))
                  }
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     columnGap: '1.5rem'
                  }}
               >
                  <Box onClick={() => setEnquiryForm(true)}>
                     <PrimaryButton
                        text='Enquiry Now'
                        bgColor='#222'
                        textColor='primary.main'
                     />
                  </Box>
                  <IconButton
                     onClick={() => setMenu(!menu)}
                     sx={theme => ({
                        display: 'none',
                        backgroundColor: '#ffd166',
                        [theme.breakpoints.down(850)]: {
                           display: 'flex'
                        }
                     })}
                  >
                     <RiMenu3Fill />
                  </IconButton>
               </Box>
            </Box>
         </Box>
      </>
   );
};

export default Navbar;