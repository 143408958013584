import React, { createContext, useCallback, useReducer } from 'react';

export const uiContext = createContext();

const initialState = {
   menu: false,
   paymentForm: false,
   enquiryForm: false,
   serviceModal: false,
   galleryModal: false,
   snackbar: false,
   showSnackbar: false,
   snackbarText: ''
};

const uiReducer = (state, action) => {
   switch (action.type) {
      case 'TOGGLE_MENU':
         return {
            ...state,
            menu: action.value
         }
      case 'PAYMENT_FORM':
         return {
            ...state,
            paymentForm: action.value
         }
      case 'ENQUIRY_FORM':
         return {
            ...state,
            enquiryForm: action.value
         }
      case 'SERVICE_MODAL':
         return {
            ...state,
            serviceModal: action.value
         }
      case 'GALLERY_MODAL':
         return {
            ...state,
            galleryModal: action.value
         }
      case 'TOGGLE_SNACKBAR':
         return {
            ...state,
            snackbar: action.value
         }
      case 'SET_SNACKBAR':
         return {
            ...state,
            showSnackbar: action.value.value,
            snackbarText: action.value.text
         }
      default:
         return {
            ...state
         }
   }
};

export const ContextProvider = ({ children }) => {
   const [state, dispatch] = useReducer(uiReducer, initialState);

   const setMenu = useCallback((value) => {
      dispatch({ type: 'TOGGLE_MENU', value: value });
   }, []);

   const setPaymentForm = (value) => {
      dispatch({ type: 'PAYMENT_FORM', value: value });
   };

   const setEnquiryForm = (value) => {
      dispatch({ type: 'ENQUIRY_FORM', value: value });
   };

   const setServiceModal = (value) => {
      dispatch({ type: 'SERVICE_MODAL', value: value });
   };

   const setGalleryModal = (value) => {
      dispatch({ type: 'GALLERY_MODAL', value: value });
   };

   const toggleSnackbar = (value) => {
      dispatch({ type: 'TOGGLE_SNACKBAR', value: value });
   };

   const setSnackbar = (value) => {
      dispatch({ type: 'SET_SNACKBAR', value: value });
   };

   const createContext = {
      menu: state.menu,
      paymentForm: state.paymentForm,
      enquiryForm: state.enquiryForm,
      serviceModal: state.serviceModal,
      galleryModal: state.galleryModal,
      snackbar: state.snackbar,
      showSnackbar: state.showSnackbar,
      snackbarText: state.snackbarText,
      setMenu: setMenu,
      setServiceModal: setServiceModal,
      setPaymentForm: setPaymentForm,
      setEnquiryForm: setEnquiryForm,
      setGalleryModal: setGalleryModal,
      toggleSnackbar: toggleSnackbar,
      setSnackbar: setSnackbar
   };

   return (
      <uiContext.Provider value={createContext}>
         {children}
      </uiContext.Provider>
   );
};