import React, { Fragment, useCallback, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CustomTextfield from '../UI/CustomTextfield';
import PrimaryButton from '../UI/PrimaryButton';
import { FaTimes } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { uiContext } from '../../context/ContextProvider';
import CustomSelectMenu from '../UI/CustomSelectMenu';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';

const style = theme => ({
   position: 'absolute',
   top: '50%',
   left: '50%',
   maxHeight: '90vh',
   overflowY: 'auto',
   transform: 'translate(-50%, -50%)',
   maxWidth: '800px',
   width: '100%',
   bgcolor: 'background.paper',
   boxShadow: 24,
   p: 4,
   [theme.breakpoints.down('md')]: {
      width: '90%'
   },
   [theme.breakpoints.down('sm')]: {
      p: 3
   }
});

const gridStyle = theme => ({
   marginTop: '1.5rem',
   display: 'grid',
   gridTemplateColumns: 'repeat(2, 1fr)',
   columnGap: '16px',
   rowGap: '10px',
   [theme.breakpoints.down(768)]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
   },
   [theme.breakpoints.down(600)]: {
      gridTemplateColumns: '1fr'
   }
});

const fields = [
   {
      label: 'First Name',
      name: 'fname',
      type: 'text'
   },
   {
      label: 'Last Name',
      name: 'lname',
      type: 'text'
   },
   {
      label: 'Side Name',
      name: 'sname',
      type: 'text'
   },
   {
      label: 'ID No.',
      name: 'idno',
      type: 'text'
   },
   {
      label: 'Email',
      name: 'email',
      type: 'email'
   },
   {
      label: 'Total Duty in Running Month (No of Days)',
      name: 'totalduty',
      type: 'text'
   },
   {
      label: 'Advance Amount Requested',
      name: 'advamount',
      type: 'text',
      fieldItems: ['500', '1000', '1500', '2000']
   },
   {
      label: 'Amount in Words',
      name: 'amounttowards',
      type: 'text'
   },
   {
      label: 'Upload Photo With Uniform',
      name: 'uploadphoto',
      type: 'file'
   }
];

const defaultValues = {
   fname: '',
   lname: '',
   sname: '',
   idno: '',
   email: '',
   totalduty: '',
   amounttowards: ''
};

const PaymentFormModal = () => {
   const { paymentForm, setPaymentForm, toggleSnackbar, setSnackbar } = useContext(uiContext);

   const [loading, setLoading] = useState(false);
   const [amount, setAmount] = useState(500);

   const handleClose = useCallback(() => setPaymentForm(false), [setPaymentForm]);

   const validationSchema = Yup.object().shape({
      fname: Yup.string()
         .required('First Name is required'),
      lname: Yup.string()
         .required('Last Name is required'),
      sname: Yup.string()
         .required('Side Name is required'),
      idno: Yup.string()
         .required('ID No. is required'),
      email: Yup.string()
         .required('Email is required'),
      totalduty: Yup.string()
         .required('Duty is required'),
      amounttowards: Yup.string()
         .required('Amount is required'),
      uploadphoto: Yup.mixed()
         .test('required', 'Image file is required', (value) => {
            return value && value.length
         })
         .test('fileSize', 'File size must be under 5mb', (value) => {
            return value && value[0] && value[0].size <= 5000000;
         })
         .test('type', 'We only support JPG, PNG, SVG & GIF', (value) => {
            return value && value[0] && (
               value[0].type === 'image/jpeg' ||
               value[0].type === 'image/png' ||
               value[0].type === 'image/svg' ||
               value[0].type === 'image/gif'
            );
         })
   });

   const {
      register,
      handleSubmit,
      reset,
      formState: {
         errors
      }
   } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: defaultValues
   });

   // Submit Form
   const handleForm = async (data) => {
      try {
         setLoading(true);
         await axios.post('https://infogps.in/gpsbackend/wp-json/contact-form-7/v1/contact-forms/306/feedback', { ...data, advamount: amount }, {
            headers: {
               "Content-Type": "multipart/form-data",
            }
         });

         reset();
         setAmount(500);
         setLoading(false);
         setPaymentForm(false);
         toggleSnackbar(true);
         setSnackbar({ value: true, text: 'Successfully submitted!' });
      } catch (error) {
         setLoading(false);
         toggleSnackbar(true);
         setSnackbar({ value: true, text: error.message });
      }
   };

   return (
      <div>
         <Modal
            open={paymentForm}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <form onSubmit={handleSubmit((data) => handleForm(data))}>
               <Box sx={style}>
                  <Box>
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'space-between'
                        }}
                     >
                        <Typography
                           id="modal-modal-title"
                           variant="h6"
                           component="h2"
                           sx={theme => ({
                              fontSize: '1.5rem',
                              fontWeight: 600,
                              [theme.breakpoints.down('md')]: {
                                 fontSize: '1.25rem'
                              },
                              [theme.breakpoints.down('sm')]: {
                                 fontSize: '1.05rem'
                              }
                           })}
                        >
                           Request For Advance Payment
                        </Typography>
                        <IconButton
                           onClick={() => setPaymentForm(false)}
                           sx={{ marginBottom: '5px' }}
                        >
                           <FaTimes />
                        </IconButton>
                     </Box>
                     <Box sx={gridStyle}>
                        {
                           fields.map((item, index) => (
                              <Fragment key={index}>
                                 {
                                    item.name === 'advamount' ? (
                                       <CustomSelectMenu
                                          label={item.label}
                                          value={amount}
                                          setValue={setAmount}
                                          menuItems={item.fieldItems}
                                       />
                                    ) : (
                                       <CustomTextfield
                                          label={item.label}
                                          type={item.type}
                                          name={item.name}
                                          register={register}
                                          errors={errors}
                                       />
                                    )
                                 }
                              </Fragment>
                           ))
                        }
                     </Box>
                  </Box>
                  <Box sx={{ margin: '1.5rem 0 1rem 0' }}>
                     <PrimaryButton
                        loading={loading}
                        text='Submit'
                        bgColor='text.primary'
                        textColor='primary.main'
                        width='91px'
                     />
                  </Box>
               </Box>
            </form>
         </Modal>
      </div>
   );
};

export default PaymentFormModal;