import React from 'react';
import { Box } from '@mui/material';
import { ThreeCircles } from 'react-loader-spinner';

const Spinner = () => {
   return (
      <Box
         sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
         }}
      >
         <ThreeCircles
            height="75"
            width="75"
            color="#e6a100"
            visible={true}
            ariaLabel="three-circles-rotating"
         />
      </Box>
   );
};

export default Spinner;