import React, { Fragment, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CustomTextfield from '../UI/CustomTextfield';
import PrimaryButton from '../UI/PrimaryButton';
import { FaTimes } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { uiContext } from '../../context/ContextProvider';
import CustomSelectMenu from '../UI/CustomSelectMenu';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';

const style = theme => ({
   position: 'absolute',
   top: '50%',
   left: '50%',
   maxHeight: '90vh',
   overflowY: 'auto',
   transform: 'translate(-50%, -50%)',
   maxWidth: '800px',
   width: '100%',
   bgcolor: 'background.paper',
   boxShadow: 24,
   p: 4,
   [theme.breakpoints.down('md')]: {
      width: '90%'
   },
   [theme.breakpoints.down('sm')]: {
      p: 3
   }
});

const gridStyle = theme => ({
   marginTop: '1.5rem',
   display: 'grid',
   gridTemplateColumns: 'repeat(2, 1fr)',
   columnGap: '16px',
   rowGap: '10px',
   [theme.breakpoints.down(768)]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
   },
   [theme.breakpoints.down(600)]: {
      gridTemplateColumns: '1fr'
   }
});

const fields = [
   {
      label: 'What Services Do You Need?',
      name: 'services',
      type: 'text',
      fieldItems: [
         'Security',
         'Facility Management',
         'House Keeping',
         'Bouncers and Bodyguard',
         'Receptionist',
         'Back and Front Office Executive',
         'Valet Services',
         'Telecaller',
         'Service Boy',
         'Office Peon',
         'Sales Executive',
         'KST',
         'Safe Helper',
         'Computer Operator',
         'Security Officer'
      ]
   },
   {
      label: 'Your Name',
      name: 'sname',
      type: 'text'
   },
   {
      label: 'Company Name',
      name: 'cname',
      type: 'text'
   },
   {
      label: 'Upload Your Visiting Card',
      name: 'vcard',
      type: 'file'
   },
   {
      label: 'Designation',
      name: 'designation',
      type: 'text'
   },
   {
      label: 'Location',
      name: 'location',
      type: 'text'
   },
   {
      label: 'Mobile',
      name: 'mobile',
      type: 'text'
   },
   {
      label: 'Email',
      name: 'email',
      type: 'Email'
   }
];

const defaultValues = {
   sname: '',
   cname: '',
   vcard: null,
   designation: '',
   location: '',
   mobile: '',
   email: ''
};

const EnquiryNowFormModal = () => {
   const { enquiryForm, setEnquiryForm, toggleSnackbar, setSnackbar } = useContext(uiContext);

   const [loading, setLoading] = useState(false);
   const [services, setServices] = useState('Security');

   const handleClose = () => setEnquiryForm(false);

   const validationSchema = Yup.object().shape({
      sname: Yup.string()
         .required('Name is required')
         .min(2, 'Name must be at least 2 characters')
         .max(100, 'Name must not exceed 100 characters')
         .matches(/^\s*([A-Za-z]{1,}([.,] |[-']| ))+[A-Za-z]+.?\s*$/, 'Please enter a valid full name'),
      cname: Yup.string()
         .required('Company name is required')
         .min(2, 'Company name must be at least 2 characters')
         .max(150, 'Company name must not exceed 150 characters'),
      vcard: Yup.mixed()
         .test('required', 'Image file is required', (value) => {
            return value && value.length
         })
         .test('fileSize', 'File size must be under 5mb', (value) => {
            return value && value[0] && value[0].size <= 5000000;
         })
         .test('type', 'We only support JPG, PNG, SVG & GIF', (value) => {
            return value && value[0] && (
               value[0].type === 'image/jpeg' ||
               value[0].type === 'image/png' ||
               value[0].type === 'image/svg' ||
               value[0].type === 'image/gif'
            );
         }),
      designation: Yup.string()
         .required('Designation is required')
         .min(2, 'Designation must be at least 2 characters')
         .max(150, 'Designation must not exceed 150 characters'),
      location: Yup.string()
         .required('Location is required')
         .min(2, 'Location must be at least 2 characters')
         .max(150, 'Location must not exceed 150 characters'),
      mobile: Yup.string()
         .required('Phone number is required')
         .max(20, 'Phone number must not exceed 20 characters'),
      email: Yup.string()
         .required('Email is required')
         .email('Email is invalid')
         .matches(/^([a-z0-9_.-]+)@([a-z\d-]+)\.([a-z]{2,10})(\.[a-z]{2,10})?$/, 'Please enter a valid email address')
   });

   const {
      register,
      handleSubmit,
      reset,
      formState: {
         errors
      }
   } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: defaultValues
   });

   // Submit Form
   const handleForm = async (data) => {
      try {
         setLoading(true);
         await axios.post('https://infogps.in/gpsbackend/wp-json/contact-form-7/v1/contact-forms/305/feedback', { ...data, services: services }, {
            headers: {
               "Content-Type": "multipart/form-data",
            }
         });

         reset();
         setServices('Security');
         setLoading(false);
         setEnquiryForm(false);
         toggleSnackbar(true);
         setSnackbar({ value: true, text: 'Successfully submitted!' });
      } catch (error) {
         setLoading(false);
         toggleSnackbar(true);
         setSnackbar({ value: true, text: error.message });
      }
   };

   return (
      <div>
         <Modal
            open={enquiryForm}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <form onSubmit={handleSubmit((data) => handleForm(data))}>
               <Box sx={style}>
                  <Box>
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'space-between'
                        }}
                     >
                        <Typography
                           id="modal-modal-title"
                           variant="h6"
                           component="h2"
                           sx={theme => ({
                              fontSize: '1.5rem',
                              fontWeight: 600,
                              [theme.breakpoints.down('md')]: {
                                 fontSize: '1.25rem'
                              },
                              [theme.breakpoints.down('sm')]: {
                                 fontSize: '1.05rem'
                              }
                           })}
                        >
                           Enquiry to Services
                        </Typography>
                        <IconButton
                           onClick={() => setEnquiryForm(false)}
                           sx={{ marginBottom: '5px' }}
                        >
                           <FaTimes />
                        </IconButton>
                     </Box>
                     <Box sx={gridStyle}>
                        {
                           fields.map((item, index) => (
                              <Fragment key={index}>
                                 {
                                    item.name === 'services' ? (
                                       <CustomSelectMenu
                                          label={item.label}
                                          value={services}
                                          setValue={setServices}
                                          menuItems={item.fieldItems}
                                       />
                                    ) : (
                                       <CustomTextfield
                                          label={item.label}
                                          type={item.type}
                                          name={item.name}
                                          register={register}
                                          errors={errors}
                                       />
                                    )
                                 }
                              </Fragment>
                           ))
                        }
                     </Box>
                  </Box>
                  <Box sx={{ margin: '1.5rem 0 1rem 0' }}>
                     <PrimaryButton
                        loading={loading}
                        text='Submit'
                        bgColor='text.primary'
                        textColor='primary.main'
                        width='91px'
                     />
                  </Box>
               </Box>
            </form>
         </Modal>
      </div>
   );
};

export default EnquiryNowFormModal;