import React from 'react';
import { Box } from '@mui/material';
import SectionTitle from '../UI/SectionTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PrimaryButton from '../UI/PrimaryButton';
import { Link } from 'react-router-dom';

const Gallery = ({ homeData }) => {
   const galleryImages = homeData.gallery_reapeter.map(item => item.gallery_image);

   return (
      <Box
         sx={theme => ({
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            marginTop: '6rem',
            position: 'relative',
            minHeight: '575px',
            [theme.breakpoints.down('xl')]: {
               marginTop: '5rem'
            },
            [theme.breakpoints.down('lg')]: {
               marginTop: '4rem',
               minHeight: '555px'
            },
            [theme.breakpoints.down('md')]: {
               marginTop: '3.5rem'
            },
            [theme.breakpoints.down('sm')]: {
               marginTop: '3rem',
               minHeight: '525px'
            },
            [theme.breakpoints.down('xm')]: {
               marginTop: '2.5rem'
            }
         })}
      >
         <Box
            sx={{
               position: 'absolute',
               height: '100%',
               width: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.75)',
               zIndex: 1
            }}
         />

         <Box
            sx={{
               zIndex: 11,
               position: 'absolute',
               height: '100%',
               width: '100%'
            }}
         >
            <Box className='container'>
               <Box
                  sx={theme => ({
                     paddingTop: '4rem',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'flex-start',
                     rowGap: '2rem',
                     [theme.breakpoints.down('sm')]: {
                        paddingTop: '3rem'
                     }
                  })}
               >
                  <SectionTitle
                     text={homeData.gallery_title.toUpperCase()}
                     color='primary.main'
                  />
               </Box>
            </Box>

            <Box className='container gallery-swiper' sx={{ marginTop: '-1rem' }}>
               {/* Slider */}
               <Swiper
                  slidesPerView={4}
                  slidesPerGroup={1}
                  loop={true}
                  spaceBetween={24}
                  modules={[Pagination]}
                  pagination={{
                     clickable: true
                  }}
                  breakpoints={{
                     250: {
                        slidesPerView: 1,
                     },
                     450: {
                        slidesPerView: 2,
                     },
                     768: {
                        slidesPerView: 3,
                     },
                     1250: {
                        slidesPerView: 4
                     }
                  }}
               >
                  {
                     galleryImages.map((img, index) => (
                        <SwiperSlide key={index}>
                           <Box
                              sx={{
                                 padding: '5px',
                                 backgroundColor: 'primary.main',
                                 display: 'flex',
                                 alignItems: 'center'
                              }}
                           >
                              <img
                                 src={img}
                                 alt=""
                                 style={{
                                    height: '200px',
                                    width: '100%',
                                    objectFit: 'cover'
                                 }}
                              />
                           </Box>
                        </SwiperSlide>
                     ))
                  }
               </Swiper>

               <Box
                  sx={{
                     marginTop: '2rem',
                     display: 'flex',
                     justifyContent: 'center'
                  }}
               >
                  <Link to='/gallery'>
                     <PrimaryButton
                        text='View All'
                        bgColor='primary.main'
                        textColor='text.primary'
                     />
                  </Link>
               </Box>
            </Box>
         </Box>
      </Box >
   );
};

export default Gallery;