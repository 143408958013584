import React, { Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import './index.css';
import Home from './pages/Home';
import { Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Spinner from './components/UI/Spinner';
import Wrapper from './components/Wrapper/Wrapper';

const Services = React.lazy(() => import('./pages/Services'));
const Apply = React.lazy(() => import('./pages/Apply'));
const Products = React.lazy(() => import('./pages/Products'));
const Gallery = React.lazy(() => import('./pages/Gallery'));
const Contact = React.lazy(() => import('./pages/Contact'));

function App() {
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				xm: 450,
				sm: 650,
				md: 950,
				lg: 1280,
				xl: 1536,
			},
		},
		palette: {
			primary: {
				main: '#FFE19B',
			},
			secondary: {
				main: '#111',
			},
			text: {
				primary: '#111',
				secondary: '#868395',
				disabled: '#a19fad',
				main: '#b37d00'
			},
		},
		typography: {
			fontFamily: 'Poppins, sans-serif',
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: `
				 @font-face {
					font-family: 'Poppins';
					font-style: normal;
					font-weight: 400;
				 }
			  `,
			},
			MuiCard: {
				styleOverrides: {
					root: {
						border: 'none',
						borderRadius: 0,
						boxShadow: 'rgb(50, 60, 78 / 11%) 0px 7px 30px 0px'
					}
				}
			}
		}
	});

	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<Wrapper>
					<Suspense fallback={<Spinner />}>
						<Switch>
							<Route path='/' exact>
								<Home />
							</Route>
							<Route path='/services' exact>
								<Services />
							</Route>
							<Route path='/applyforjob' exact>
								<Apply />
							</Route>
							<Route path='/contact' exact>
								<Contact />
							</Route>
							<Route path='/products' exact>
								<Products />
							</Route>
							<Route path='/gallery' exact>
								<Gallery />
							</Route>
							<Route path='*'>
								<Redirect to='/' />
							</Route>
						</Switch>
					</Suspense>
				</Wrapper>
			</ThemeProvider>
		</QueryClientProvider>
	);
};

export default App;
