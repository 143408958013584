import React from 'react';
import Hero from './Hero';
import About from './About';
import { useMediaQuery, useTheme } from '@mui/material';
import Clients from './Clients';
import Services from './Services';
import Gallery from './Gallery';

const HomeComponents = ({ bannerData, homeData }) => {
   const theme = useTheme();
   const xlWidth = useMediaQuery(theme.breakpoints.down('xl'));
   const mdWidth = useMediaQuery(theme.breakpoints.down('md'));
   const smWidth = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <>
         <Hero
            smWidth={smWidth}
            xlWidth={xlWidth}
            bannerData={bannerData}
         />
         <About
            mdWidth={mdWidth}
            homeData={homeData}
         />
         <Clients homeData={homeData} />
         <Services homeData={homeData} />
         <Gallery homeData={homeData} />
      </>
   );
};

export default HomeComponents;