import React, { useRef } from 'react';
import { Box, Button } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";
import '../../cardswiper.css';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import HeroSliderCard from '../UI/HeroSliderCard';

const Hero = ({ smWidth, xlWidth, bannerData }) => {
   const swiperRef = useRef();

   const heroData = bannerData.map(item => ({
      topText: item.acf.sub_title,
      title: item.title.rendered,
      subtitle: item.content.rendered,
      img: item.acf.banner_image
   }));

   return (
      <Box
         className='swiper-js-container'
         sx={theme => ({
            padding: '45px 0 0 0',
            [theme.breakpoints.down('md')]: {
               padding: '20px 0 0 0'
            }
         })}
      >
         <Box className='swiper-button-container'>
            <Button
               className='swiper-button'
               onClick={() => swiperRef.current?.slidePrev()}
            >
               <IoIosArrowBack style={{ fontSize: '2rem' }} />
            </Button>
            <Button
               className='swiper-button'
               onClick={() => swiperRef.current?.slideNext()}
            >
               <IoIosArrowForward style={{ fontSize: '2rem' }} />
            </Button>
         </Box>
         <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            loop={true}
            autoplay={{
               delay: 5000,
               disableOnInteraction: false
            }}
            modules={smWidth ? [Pagination, Autoplay] : [Navigation, Autoplay]}
            pagination={{
               clickable: smWidth ? true : false,
            }}
            onBeforeInit={(swiper) => swiperRef.current = swiper}
         >
            {
               heroData.map((data, index) => (
                  <SwiperSlide key={index}>
                     <HeroSliderCard
                        data={data}
                        xlWidth={xlWidth}
                     />
                  </SwiperSlide>
               ))
            }
         </Swiper>
      </Box>
   );
};

export default Hero;