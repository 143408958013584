import React, { Fragment } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FaFacebook } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { BsFillEnvelopeFill, BsFillTelephoneFill, BsYoutube } from 'react-icons/bs';
import { ImGoogle3 } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { RiWhatsappFill } from 'react-icons/ri';
import { GiRotaryPhone } from 'react-icons/gi';

const links = [
   {
      title: 'Useful Links',
      sublinks: ['Home', 'Services', 'Apply For Job', 'Gallery', 'Contact']
   }
];

const Footer = ({ data }) => {
   const theme = useTheme();
   const mdWidth = useMediaQuery(theme.breakpoints.down('md'));

   const { pathname } = useLocation();

   const socialIcons = [
      {
         icon: <FaFacebook style={{ fontSize: '1.75rem' }} />,
         link: data.facebook
      },
      {
         icon: <AiFillInstagram style={{ fontSize: '2.1rem' }} />,
         link: data.instagram
      },
      {
         icon: <ImGoogle3 style={{ fontSize: '1.75rem' }} />,
         link: data.google_plus
      },
      {
         icon: <BsYoutube style={{ fontSize: '2rem' }} />,
         link: data.youtube
      }
   ];

   const contactDetails = [
      {
         title: 'Contact Us',
         sublinks: [
            {
               icon: <BsFillEnvelopeFill style={{ fontSize: '1.25rem', marginRight: '8px' }} />,
               text: data.email,
               link: `mailto:${data.email}`
            },
            {
               icon: <BsFillTelephoneFill style={{ fontSize: '1.25rem', marginRight: '8px' }} />,
               text: `Mobile : ${data.mobile}`,
               link: `tel:${data.mobile}`
            },
            {
               icon: <GiRotaryPhone style={{ fontSize: '1.75rem' }} />,
               text: `Landline : ${data.landline}`,
               link: `tel:${data.landline}`
            },
            {
               icon: <RiWhatsappFill style={{ fontSize: '1.5rem' }} />,
               text: `Whatsapp : ${data.whatsapp}`,
               link: `https://wa.me/${data.whatsapp}`
            },
            {
               icon: <MdLocationOn style={{ fontSize: '1.5rem' }} />,
               text: data.address
            }
         ]
      }
   ];

   return (
      <Box sx={{ backgroundColor: 'primary.main' }}>
         <Box
            sx={theme => ({
               padding: '3.5rem 0 1.5rem 0',
               [theme.breakpoints.down('lg')]: {
                  padding: '3rem 0 1.5rem 0'
               },
               [theme.breakpoints.down('sm')]: {
                  padding: '2.5rem 0 1.5rem 0'
               }
            })}
         >
            <Box className='container'>
               <Grid
                  container
                  spacing={3}
                  sx={theme => ({
                     width: '90%',
                     margin: '0 auto',
                     [theme.breakpoints.down('lg')]: {
                        width: '100%'
                     }
                  })}
               >

                  {/* First Grid */}
                  <Grid
                     item xs={12} sm={9} md={4}
                     sx={theme => ({
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '1rem',
                        [theme.breakpoints.down('md')]: {
                           marginBottom: '1rem'
                        }
                     })}
                  >
                     <Box>
                        <img
                           src={data.logo}
                           alt=""
                           style={{
                              height: mdWidth ? '75px' : '100px'
                           }}
                        />
                     </Box>
                     <Typography sx={{ fontSize: '15px', fontWeight: 500 }}>
                        {data.footer_small_content}
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'flex-start',
                           columnGap: '10px'
                        }}
                     >
                        {
                           socialIcons.map((item, index) => (
                              <Fragment key={index}>
                                 <a
                                    href={item.link}
                                    target='_blank'
                                    rel="noreferrer"
                                    style={{
                                       color: '#111',
                                       display: 'flex',
                                       alignItems: 'center',
                                    }}
                                 >
                                    {item.icon}
                                 </a>
                              </Fragment>
                           ))
                        }
                     </Box>
                  </Grid>

                  {/* Second Grid */}
                  <Grid
                     item xs={12} sm={5} md={4}
                     sx={theme => ({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                           alignItems: 'flex-start'
                        }
                     })}
                  >
                     {
                        links.map((item, index) => (
                           <Box key={index}>
                              <Typography
                                 sx={{
                                    fontWeight: 700,
                                    marginBottom: '1.5rem',
                                    fontSize: '1.1rem'
                                 }}
                              >
                                 {item.title}
                              </Typography>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '1rem'
                                 }}
                              >
                                 {
                                    item.sublinks.map((link, index) => (
                                       <Link
                                          key={index}
                                          to={link === 'Home' ? '/' : `/${link.split(' ').join('').toLowerCase()}`}
                                       >
                                          <Typography
                                             sx={{
                                                fontWeight: 500,
                                                fontSize: '15px',
                                                color: (pathname === '/' ? '/home' : pathname) === `/${link.split(' ').join('').toLowerCase()}` ? 'text.main' : 'text.primary',
                                             }}
                                          >
                                             {link}
                                          </Typography>
                                       </Link>
                                    ))
                                 }
                              </Box>
                           </Box>
                        ))
                     }
                  </Grid>

                  {/* Third Grid */}
                  <Grid
                     item xs={12} sm={7} md={4}
                     sx={theme => ({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                           alignItems: 'flex-start'
                        },
                        [theme.breakpoints.down('md')]: {
                           marginTop: '1rem'
                        }
                     })}
                  >
                     {
                        contactDetails.map((item, index) => (
                           <Box key={{ index }}>
                              <Typography
                                 sx={{
                                    fontWeight: 700,
                                    marginBottom: '1.5rem',
                                    fontSize: '1.1rem'
                                 }}
                              >
                                 {item.title}
                              </Typography>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '1rem'
                                 }}
                              >
                                 {
                                    item.sublinks.map((sublink, index) => (
                                       <Fragment key={index}>
                                          {
                                             sublink.link ? (
                                                <a
                                                   href={sublink.link}
                                                   target="_blank"
                                                   rel="noreferrer"
                                                   style={{
                                                      display: 'flex',
                                                      columnGap: '10px',
                                                      alignItems: 'flex-start',
                                                      justifyContent: 'flex-start',
                                                      color: '#111'
                                                   }}
                                                >
                                                   <Box>
                                                      {sublink.icon}
                                                   </Box>
                                                   <Typography
                                                      sx={{
                                                         fontWeight: 500,
                                                         fontSize: '15px',
                                                         wordBreak: 'break-all'
                                                      }}
                                                   >
                                                      {sublink.text}
                                                   </Typography>
                                                </a>
                                             ) : (
                                                <Box
                                                   sx={{
                                                      display: 'flex',
                                                      columnGap: '10px',
                                                      alignItems: 'flex-start',
                                                      justifyContent: 'flex-start'
                                                   }}
                                                >
                                                   <Box>
                                                      {sublink.icon}
                                                   </Box>
                                                   <Typography
                                                      sx={{
                                                         fontWeight: 500,
                                                         fontSize: '15px',
                                                         wordBreak: 'break-all'
                                                      }}
                                                      dangerouslySetInnerHTML={{ __html: sublink.text }}
                                                   />
                                                </Box>
                                             )
                                          }
                                       </Fragment>
                                    ))
                                 }
                              </Box>
                           </Box>
                        ))
                     }
                  </Grid>
               </Grid>
               <a href='https://onqanet.com' target='_blank' rel="noreferrer">
                  <Typography
                     sx={{
                        color: 'text.primary',
                        fontWeight: 600,
                        textAlign: 'center',
                        margin: '2rem auto 0 auto'
                     }}
                  >
                     &copy; 2023 All Rights Reserved. <span style={{ color: '#b37d00' }}>Onqanet Technologies Pvt. Ltd.</span>
                  </Typography>
               </a>
            </Box>
         </Box>
      </Box >
   );
};

export default Footer;