import React from 'react';
import Navbar from '../Navigation/Navbar';
import Sidebar from '../Navigation/Sidebar';
import { useMediaQuery, useTheme } from '@mui/material';

const Navigation = ({ data }) => {
   const theme = useTheme();
   const mdWidth = useMediaQuery(theme.breakpoints.down('md'));
   const showDrawer = useMediaQuery(theme.breakpoints.down(850));

   return (
      <>
         <Navbar
            data={data}
            mdWidth={mdWidth}
            showDrawer={showDrawer}
         />
         {showDrawer && <Sidebar />}
      </>
   );
};

export default Navigation;