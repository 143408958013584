import React from 'react';
import { Typography } from '@mui/material';

const SectionTitle = ({ text, color, mb }) => {
   return (
      <Typography
         sx={theme => ({
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 700,
            marginBottom: mb ? mb : '4rem',
            color: color,
            [theme.breakpoints.down('xl')]: {
               marginBottom: mb ? mb : '3.5rem'
            },
            [theme.breakpoints.down('lg')]: {
               fontSize: '1.35rem',
               marginBottom: mb ? mb : '3rem'
            },
            [theme.breakpoints.down('md')]: {
               marginBottom: mb ? mb : '2.5rem'
            },
            [theme.breakpoints.down('sm')]: {
               marginBottom: mb ? mb : '2.25rem'
            }
         })}
      >
         {text}
      </Typography>
   );
};

export default SectionTitle;