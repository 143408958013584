import React from 'react';
import { Box } from '@mui/material';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import SectionTitle from '../UI/SectionTitle';

const Clients = ({ homeData }) => {
   const clientImages = homeData.client_image_repeater.map(item => item.client_logo.url);

   return (
      <Box
         className='container'
         sx={theme => ({
            paddingTop: '4rem',
            [theme.breakpoints.down('lg')]: {
               paddingTop: '2rem'
            },
            [theme.breakpoints.down('md')]: {
               paddingTop: '1rem'
            }
         })}
      >
         <Box className='section-padding'>
            <SectionTitle
               text='OUR CLIENTS'
               color='text.primary'
            />

            <Swiper
               slidesPerView={4}
               slidesPerGroup={1}
               loop={true}
               spaceBetween={24}
               autoplay={{
                  delay: 2000,
                  disableOnInteraction: false
               }}
               modules={[Autoplay]}
               breakpoints={{
                  250: {
                     slidesPerView: 1,
                  },
                  450: {
                     slidesPerView: 2,
                  },
                  768: {
                     slidesPerView: 3,
                  },
                  1250: {
                     slidesPerView: 4
                  }
               }}
            >
               {
                  clientImages.map((img, index) => (
                     <SwiperSlide key={index}>
                        <Box>
                           <img
                              src={img}
                              alt=""
                              style={{
                                 height: '175px',
                                 width: '100%',
                                 objectFit: 'cover'
                              }}
                           />
                        </Box>
                     </SwiperSlide>
                  ))
               }
            </Swiper>
         </Box>
      </Box>
   );
};

export default Clients;