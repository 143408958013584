import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { AiFillCheckCircle } from 'react-icons/ai';

const About = ({ mdWidth, homeData }) => {
   const [expanded, setExpanded] = React.useState('panel1');

   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };

   const accordionDetails = [
      {
         text: homeData.mission_title.toUpperCase(),
         subtext: homeData.mission_content
      },
      {
         text: homeData.vision_title.toUpperCase(),
         subtext: homeData.vision_content
      }
   ];

   return (
      <Box className='container'>
         <Grid
            container
            className='section-padding'
            spacing={2}
            rowSpacing={mdWidth ? 18 : 0}
         >
            <Grid item xs={12} md={6}>
               <Box
                  sx={theme => ({
                     width: '70%',
                     borderRadius: '10px',
                     position: 'relative',
                     [theme.breakpoints.down('md')]: {
                        margin: '0 auto'
                     },
                     [theme.breakpoints.down('sm')]: {
                        width: '100%'
                     }
                  })}
               >
                  <img
                     src={homeData.security_left_image}
                     alt=""
                     style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        borderRadius: '10px'
                     }}
                  />
                  <Box
                     sx={theme => ({
                        position: 'absolute',
                        left: '20%',
                        bottom: '-90px',
                        width: '400px',
                        backgroundColor: 'primary.main',
                        fontSize: '1.25rem',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '2rem 1.5rem',
                        fontWeight: 700,
                        borderRadius: '10px',
                        textTransform: 'uppercase',
                        [theme.breakpoints.down(1200)]: {
                           padding: '1.5rem 1rem',
                           width: '375px',
                           left: '10%',
                           fontSize: '1.15rem'
                        },
                        [theme.breakpoints.down('md')]: {
                           left: '50%',
                           transform: 'translateX(-50%)'
                        },
                        [theme.breakpoints.down('sm')]: {
                           width: '350px',
                           bottom: '-100px'
                        },
                        [theme.breakpoints.down('xm')]: {
                           width: '275px',
                           bottom: '-100px'
                        }
                     })}
                  >
                     {homeData.security_experience}
                  </Box>
               </Box>
            </Grid>
            <Grid
               item xs={12} md={6}
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '0.25rem'
               }}
            >
               <Typography
                  sx={theme => ({
                     color: 'text.main',
                     fontSize: '1.25rem',
                     textTransform: 'uppercase',
                     [theme.breakpoints.down('sm')]: {
                        textAlign: 'center'
                     }
                  })}
               >
                  {homeData.welcome_sub_title}
               </Typography>
               <Typography
                  sx={theme => ({
                     fontSize: '3rem',
                     fontWeight: 600,
                     marginBottom: '1rem',
                     color: 'text.primary',
                     textTransform: 'uppercase',
                     [theme.breakpoints.down('xl')]: {
                        fontSize: '2.75rem'
                     },
                     [theme.breakpoints.down('lg')]: {
                        fontSize: '2.5rem'
                     },
                     [theme.breakpoints.down('md')]: {
                        fontSize: '2.25rem'
                     },
                     [theme.breakpoints.down('sm')]: {
                        fontSize: '1.75rem',
                        textAlign: 'center'
                     },
                     [theme.breakpoints.down('xm')]: {
                        fontSize: '1.5rem'
                     }
                  })}
               >
                  {homeData.welcome_title}
               </Typography>
               <Typography
                  sx={theme => ({
                     fontWeight: 500,
                     color: 'text.secondary',
                     marginBottom: '2rem',
                     [theme.breakpoints.down('sm')]: {
                        fontSize: '15px',
                        textAlign: 'center'
                     }
                  })}
                  dangerouslySetInnerHTML={{ __html: homeData.wecome_content }}
               />

               {/* Accordion */}
               {
                  accordionDetails.map((item, index) => (
                     <Box key={index} sx={{ marginTop: '1rem' }}>
                        {
                           index === 0 ? (
                              <Accordion
                                 elevation={0}
                                 sx={{ backgroundColor: 'primary.main' }}
                                 expanded={expanded === 'panel1'}
                                 onChange={handleChange('panel1')}
                              >
                                 <AccordionSummary
                                    expandIcon={<MdExpandMore style={{ fontSize: '1.5rem', color: '#000' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                 >
                                    <Typography
                                       sx={{
                                          fontWeight: 600,
                                          display: 'flex',
                                          alignItems: 'center',
                                          columnGap: '10px'
                                       }}
                                    >
                                       <AiFillCheckCircle style={{ fontSize: '1.5rem' }} /> {item.text}
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                    <Typography
                                       sx={{
                                          color: 'text.primary',
                                          fontWeight: 500
                                       }}
                                       dangerouslySetInnerHTML={{ __html: item.subtext }}
                                    />
                                 </AccordionDetails>
                              </Accordion>
                           ) : (
                              <Accordion
                                 elevation={0}
                                 sx={{ backgroundColor: 'primary.main' }}
                              >
                                 <AccordionSummary
                                    expandIcon={<MdExpandMore style={{ fontSize: '1.5rem', color: '#000' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                 >
                                    <Typography
                                       sx={{
                                          fontWeight: 600,
                                          display: 'flex',
                                          alignItems: 'center',
                                          columnGap: '10px'
                                       }}
                                    >
                                       <AiFillCheckCircle style={{ fontSize: '1.5rem' }} /> {item.text}
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                    <Typography
                                       sx={{
                                          color: 'text.primary',
                                          fontWeight: 500
                                       }}
                                       dangerouslySetInnerHTML={{ __html: item.subtext }}
                                    />
                                 </AccordionDetails>
                              </Accordion>
                           )
                        }
                     </Box>
                  ))
               }
            </Grid>
         </Grid>
      </Box>
   );
};

export default About;