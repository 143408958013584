import React from 'react';
import { Box, Typography } from '@mui/material';
import errorIcon from '../../assets/error.png';
import PrimaryButton from './PrimaryButton';

const ErrorCard = ({ text }) => {
   return (
      <Box
         sx={{
            marginTop: '118px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '50vh'
         }}
      >
         <Box
            sx={{
               margin: '5rem 0',
               padding: '2rem',
               maxWidth: '400px',
               width: '100%',
               borderRadius: '10px',
               textAlign: 'center',
               backgroundColor: '#111'
            }}
         >
            <img
               src={errorIcon} alt=""
               style={{ height: '75px', width: '75px' }}
            />
            <Typography
               sx={{
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  color: 'primary.main'
               }}
            >
               Uh Oh!
            </Typography>
            <Typography
               sx={{
                  color: 'text.disabled',
                  marginBottom: '1rem',
                  fontSize: '15px'
               }}
            >
               Something went wrong. Please refresh the page or try again later!
            </Typography>
            <Box onClick={() => document.location.reload()}>
               <PrimaryButton
                  text='Refresh'
                  bgColor='primary.main'
                  textColor='text.primary'
               />
            </Box>
         </Box>
      </Box>
   );
};

export default ErrorCard;