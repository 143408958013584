import React from 'react';
import { Box, Typography } from '@mui/material';
import PrimaryButton from './PrimaryButton';
import { Link } from 'react-router-dom';

const HeroSliderCard = ({ data, xlWidth }) => {
   return (
      <Box
         sx={{
            paddingTop: '70px',
            position: 'relative',
            minHeight: '400px',
            width: '100%',
            maxHeight: xlWidth ? '550px' : '600px',
            height: '100%',
         }}
      >
         <img
            src={data.img}
            alt=""
            style={{
               minHeight: '400px',
               width: '100%',
               maxHeight: xlWidth ? '550px' : '600px',
               height: '100%',
               objectFit: 'cover'
            }}
         />

         {/* Background Shadow */}
         <Box
            sx={theme => ({
               position: 'absolute',
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               backgroundColor: 'rgba(0, 0, 0, 0.75)',
               [theme.breakpoints.down(1060)]: {
                  bottom: '6px'
               }
            })}
         />

         {/* Hero Content */}
         <Box
            sx={{
               position: 'absolute',
               top: '40px',
               left: 0,
               right: 0,
               bottom: 0,
            }}
         >
            <Box className='container' sx={{ height: '100%' }}>
               <Box
                  sx={theme => ({
                     height: '100%',
                     width: '50%',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     justifyContent: 'center',
                     rowGap: '1rem',
                     color: '#fff',
                     [theme.breakpoints.down('md')]: {
                        width: '60%',
                        rowGap: '0.5rem'
                     },
                     [theme.breakpoints.down('sm')]: {
                        width: '90%',
                        alignItems: 'center',
                        margin: '0 auto'
                     },
                     [theme.breakpoints.down('xm')]: {
                        width: '100%'
                     }
                  })}
               >
                  <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>
                     {data.topText}
                  </Typography>
                  <Typography
                     sx={theme => ({
                        fontSize: '3.5rem',
                        lineHeight: '1.3',
                        fontWeight: 600,
                        [theme.breakpoints.down('xl')]: {
                           fontSize: '3rem'
                        },
                        [theme.breakpoints.down('lg')]: {
                           fontSize: '2.5rem'
                        },
                        [theme.breakpoints.down('md')]: {
                           fontSize: '2rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                           textAlign: 'center'
                        },
                        [theme.breakpoints.down('xm')]: {
                           fontSize: '1.5rem'
                        }
                     })}
                  >
                     {data.title}
                  </Typography>
                  <Typography
                     sx={theme => ({
                        fontSize: '15px',
                        lineHeight: '1.6',
                        marginBottom: '1rem',
                        fontWeight: 300,
                        [theme.breakpoints.down('sm')]: {
                           textAlign: 'center'
                        },
                        [theme.breakpoints.down('xm')]: {
                           fontSize: '14px'
                        }
                     })}
                     dangerouslySetInnerHTML={{ __html: data.subtitle }}
                  />
                  <Link to='/services'>
                     <PrimaryButton
                        text='Read More'
                        bgColor='primary.main'
                        color='text.primary'
                     />
                  </Link>
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

export default HeroSliderCard;