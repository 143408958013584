import React from 'react';
import { Box, Fab, Zoom } from '@mui/material';
import { RiWhatsappFill } from 'react-icons/ri';

const WhatsappButton = ({ data }) => {
   return (
      <Zoom in={true}>
         <Box
            sx={{
               position: 'fixed',
               bottom: 30,
               right: 30,
               zIndex: 999
            }}
         >
            <a
               href={`https://wa.me/${data.whatsapp}`}
               target='_blank'
               rel="noreferrer"
            >
               <Fab
                  sx={{
                     backgroundColor: '#339966',
                     color: 'primary.main',
                     '&:hover': {
                        backgroundColor: '#339966'
                     }
                  }}
                  size="medium">
                  <RiWhatsappFill style={{ fontSize: '1.5rem' }} />
               </Fab>
            </a>
         </Box>
      </Zoom>
   );
};

export default WhatsappButton;