import React from 'react';
import { Box, Grid } from '@mui/material';
import SectionTitle from '../UI/SectionTitle';
import PrimaryButton from '../UI/PrimaryButton';
import ServiceCard from '../UI/ServiceCard';
import { Link } from 'react-router-dom';

const Services = ({ homeData }) => {
   const cardData = homeData.services_card.map(item => ({
      icon: item.icon,
      text: item.card_title.toUpperCase(),
      subtext: item.card_content,
      image: item.back_image
   }));

   return (
      <Box
         className='container'
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '3.5rem'
         }}
      >
         <Box className='section-padding'>
            <SectionTitle
               text={homeData.services_title.toUpperCase()}
               color='text.primary'
            />
            <Grid container spacing={4}>
               {
                  cardData.map((item, index) => (
                     <Grid key={index} item xs={12} sm={6} md={4}>
                        <ServiceCard data={item} />
                     </Grid>
                  ))
               }
            </Grid>
         </Box>

         <Link to='/services'>
            <PrimaryButton
               text='Know More'
               bgColor='#111'
               textColor='primary.main'
            />
         </Link>
      </Box>
   );
};

export default Services;