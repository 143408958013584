import React, { useContext } from 'react';
import { Box, Card, Typography } from '@mui/material';
import PrimaryButton from './PrimaryButton';
import { uiContext } from '../../context/ContextProvider';
import { useLocation } from 'react-router-dom';

const ServiceCard = ({ data, index, setPostIndex }) => {
   const { setServiceModal } = useContext(uiContext);

   const { pathname } = useLocation();

   return (
      <Card
         className='service-card'
         elevation={0}
         sx={theme => ({
            padding: '2.5rem 1.5rem',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: '1rem',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            height: '100%',
            position: 'relative',
            color: '#e6a100',
            transition: '300ms ease',
            '&:hover': {
               backgroundColor: '#FFE19B99',
               color: 'text.primary'
            },
            [theme.breakpoints.down('lg')]: {
               padding: '2rem 1.5rem'
            }
         })}
      >
         <img
            className='service-card-image'
            src={data.image}
            alt=""
            style={{
               position: 'absolute',
               height: '100%',
               width: '100%',
               objectFit: 'cover',
               zIndex: -1
            }}
         />
         <img src={data.icon} alt="" />
         <Typography
            sx={theme => ({
               textAlign: 'center',
               fontSize: '1.4rem',
               color: 'text.primary',
               fontWeight: 700,
               [theme.breakpoints.down('lg')]: {
                  fontSize: '1.25rem'
               }
            })}
         >
            {data.text}
         </Typography>
         <Typography
            className='service-card-text'
            sx={theme => ({
               textAlign: 'center',
               fontWeight: 500,
               color: 'text.primary',
               [theme.breakpoints.down('sm')]: {
                  fontSize: '15px'
               }
            })}
            dangerouslySetInnerHTML={{ __html: data.subtext }}
         />
         {
            pathname !== '/' &&
            <Box
               onClick={() => {
                  setServiceModal(true);
                  setPostIndex(index);
               }}
            >
               <PrimaryButton
                  text='Read More'
                  bgColor='text.primary'
                  textColor='primary.main'
               />
            </Box>
         }
      </Card>
   );
};

export default ServiceCard;