import React from 'react';
import HomeComponents from '../components/HomeComponents/HomeComponents';
import axios from 'axios';
import { useQuery } from 'react-query';
import Spinner from '../components/UI/Spinner';
import ErrorCard from '../components/UI/ErrorCard';

const fetcherBannerData = () => {
   return axios.get('https://infogps.in/gpsbackend/wp-json/wp/v2/banner');
};

const fetcherHomeData = () => {
   return axios.get('https://infogps.in/gpsbackend/wp-json/acf/v2/page/9');
};

const Home = () => {
   const {
      isLoading: isBannerLoading,
      data: bannerData,
      isError: isBannerError
   } = useQuery('banner-data', fetcherBannerData);

   const {
      isLoading: isHomeLoading,
      data: homeData,
      isError: isHomeError
   } = useQuery('home-data', fetcherHomeData);

   if (isBannerLoading || isHomeLoading) {
      return <Spinner />;
   };

   if (isBannerError || isHomeError) {
      return <ErrorCard />;
   };

   return (
      <>
         <HomeComponents
            bannerData={bannerData.data}
            homeData={homeData.data.acf}
         />
      </>
   );
};

export default Home;