import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

const CustomSelectMenu = ({ label, value, setValue, menuItems }) => {
   const handleChange = (event) => {
      setValue(event.target.value);
   };

   return (
      <Box sx={{ width: '100%' }}>
         <FormControl fullWidth>
            <Typography
               sx={{
                  color: 'text.primary',
                  fontWeight: 600,
                  fontSize: '15px',
                  marginBottom: '5px'
               }}
            >
               {label}
            </Typography>
            <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={value}
               onChange={handleChange}
               sx={{
                  height: 45,
                  border: "2px solid text.main",
                  fontWeight: 600,
                  backgroundColor: '#F8F9F9'
               }}
            >
               {
                  menuItems.map((item, index) => (
                     <MenuItem
                        key={index}
                        value={item}
                        sx={{
                           height: '40px',
                           fontSize: '15px',
                           color: 'text.primary',
                           fontWeight: 600
                        }}
                     >
                        {item}
                     </MenuItem>
                  ))
               }
            </Select>
         </FormControl>
      </Box>
   );
};

export default CustomSelectMenu;